.success_popup {
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: #90EE90;
  padding: 15px 30px;
  border-radius: 8px;
  color: black;
  z-index: 1000;
  animation: slideIn 0.3s ease-out forwards;
  font-size: 18px;
  font-weight: 500;
}

.success_popup.hide {
  animation: slideOut 0.3s ease-out forwards;
}

@keyframes slideIn {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideOut {
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(-100%);
    opacity: 0;
  }
} 