.card {
  display: flex;
  gap: 24px;
}
#grey_card {
  padding: 30px;
  height: 490px;
}

.second_title h5 {
  width: 55%;
}

.second_title p {
  width: 80%;
}

.icon {
  width: 100%; /* Change to 100% */
  aspect-ratio: 1 / 1; /* Maintain square shape */
  min-width: 86px; /* Limit maximum size */
  min-height: 86px; /* Limit maximum size */
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 18px;
  overflow: hidden;
}

.icon img {
  width: 100%;
  height: 100%;
  object-fit: contain; /* Changed back to contain */
}

.titles {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 25px;
  margin-bottom: 40px;
}

.titles h3 {
  margin-top: -7px;
}

.text {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 45%;
}
.second_title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
}

@media screen and (max-width: 1200px) {
  .second_title h5 {
    width: 100%;
  }

  .second_title p {
    width: 100%;
  }
}

@media screen and (max-width: 1000px) {
  #grey_card {
    height: 435px;
  }
}

@media screen and (max-width: 768px) {
  .card {
    gap: 14px;
  }
  #grey_card {
    padding: 20px;
    height: 340px;
  }
  .titles {
    flex-direction: column;
  }
  .titles h3 {
    width: 100%;
  }
  .text {
    width: 100%;
  }
  .second_title h5 {
    font-size: 20px;
  }
  .second_title p {
    font-size: 14px;
  }
}

@media screen and (max-width: 600px) {
  .card {
    flex-direction: column;
  }
  #grey_card {
    height: 280px;
  }
}
