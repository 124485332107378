.account_wrapper {
  margin: 80px auto !important;
  min-height: 70vh;
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 850px) {
  .account_wrapper {
    flex-direction: column;
  }
}

@media screen and (max-width: 570px) {
  .account_wrapper {
    margin: 45px auto !important;
  }
}
