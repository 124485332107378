@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style-type: none;
  text-decoration: none;
  font-optical-sizing: auto;
  font-family: "Roboto Condensed", sans-serif;
  color: #282828;
  font-weight: 400;
}
.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
}
h1,
h2,
h3,
h4,
h5,
p,
span {
  cursor: default;
}

.container {
  max-width: 1360px;
  min-width: 220px;
  margin: 0 auto;
  width: 92%;
}
#margin_top {
  margin-top: 180px;
}

@media screen and (max-width: 1000px) {
  #margin_top {
    margin-top: 120px;
  }
}
@media screen and (max-width: 600px) {
  #margin_top {
    margin-top: 80px;
  }
}
.roboto {
  font-family: "Roboto", sans-serif;
}

/** modal **/
.modal {
  position: fixed;
  z-index: 50;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(40, 40, 40, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  transition: 0.3s;
}
.modal_active {
  opacity: 1;
  pointer-events: all;
  transition: 0.3s;
}
.hide {
  display: none;
}

/** burger menu **/
.rounded {
  border-radius: 3px;
  background-clip: padding-box;
}
.hamburger-icon {
  display: inline-block;
  height: 30px;
  margin: 25px;
  position: relative;
  width: 30px;
}
.hamburger-icon span {
  border-radius: 3px;
  background-color: #000000;
  display: block;
  height: 1px;
  margin: 0 auto;
  position: relative;
  top: 16px;
  width: 30px;
}
.hamburger-icon span:before,
.hamburger-icon span:after {
  border-radius: 3px;
  background-color: #000000;
  content: "";
  display: block;
  height: 1px;
  position: absolute;
  width: 30px;
}
.hamburger-icon span:before {
  margin-top: -9px;
}
.hamburger-icon span:after {
  margin-top: 9px;
}
.cross-animation span {
  transition-delay: 0.2s;
  transition-duration: 0s;
}
.cross-animation span:before,
.cross-animation span:after {
  transition-delay: 0.2s, 0s;
  transition-duration: 0.2s;
  transition-property: margin, transform;
}
.cross-animation:hover span:before {
  margin-top: -9px;
}
.cross-animation:hover span:after {
  margin-top: 9px;
}
.cross-animation.open span {
  background-color: rgba(0, 0, 0, 0);
  transition-delay: 0.2s;
}
.cross-animation.open span:before {
  transform: rotate(45deg);
  margin-top: 0;
  transition-delay: 0s, 0.2s;
}
.cross-animation.open span:after {
  transform: rotate(-45deg);
  margin-top: 0;
  transition-delay: 0s, 0.2s;
}

/** run line **/
.items-wrap {
  position: relative;
  display: flex;
  overflow: hidden;
  user-select: none;
  gap: 20px;
}

.items-wrap:before,
.items-wrap:after {
  content: "";
  height: 100%;
  top: 0;
  width: 10%;
  position: absolute;
  z-index: 1;
  pointer-events: none;
}

.items-wrap:before {
  left: 0;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.171) 0%,
    rgba(255, 255, 255, 0.007) 100%
  );
}

.items-wrap:after {
  right: 0;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.171) 0%,
    rgba(255, 255, 255, 0.007) 100%
  );
}

.items {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-width: fit-content;
  animation: scroll 15s linear infinite;
}

.item {
  width: 200px;
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.item:hover {
  transform: scale(1.05);
}

.marquee {
  animation: scroll 15s linear infinite;
}

.items-wrap:hover .marquee {
  animation-play-state: paused;
}

@keyframes scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-100% - 20px));
  }
}

.rfm-marquee {
  display: flex;
  gap: 20px;
}
