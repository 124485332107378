.buttons {
  display: flex;
  align-self: flex-start;
  position: absolute;
  bottom: 60px;
  gap: 10px;
}
.registrationForm {
  max-height: 100vh;
  min-height: 100vh;
  padding: 20px 0;
}

.registrationForm form {
  position: relative;
}

.step {
  height: 1px;
  width: 10%;
  background-color: red;
}

.step_wrapper {
  display: flex;
  gap: 5px;
  position: absolute;
  bottom: 0px;
  width: 35%;
}

.step_block {
  background-color: rgba(215, 215, 215, 1);
  min-height: 5px;
  width: 20%;
}

.step_active {
  background-color: black;
}

.social_btn {
  margin-bottom: -50px;
}
@media screen and (max-width: 1250px) {
  .step_wrapper {
    bottom: 10px;
    width: 50%;
  }
}

@media screen and (max-width: 930px) {
  .step_wrapper {
    width: 45%;
  }
}

@media screen and (max-width: 768px) {
  .buttons {
    left: 10%;
  }
  .step_wrapper {
    left: 10%;
    width: 80%;
  }
}

@media screen and (max-width: 500px) {
  .buttons {
    left: 0;
  }
  .step_wrapper {
    left: 0;
    width: 100%;
  }
}

.check {
  margin-bottom: 80px;
}
