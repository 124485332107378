.grey_wrap {
  height: fit-content;
  background-color: #f5f6f6;
  padding: 15px;
  border-radius: 16px;
  width: fit-content;
}
.grey_header {
  margin-top: 40px;
  margin-bottom: 42px;
}
.title {
  margin-bottom: 20px;
}

@media screen and (max-width: 768px) {
  .grey_header {
    margin-top: 30px;
  }
}
