.header_details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: 95px;
  gap: 26px;
}

.header_details div {
  display: flex;
  gap: 8px;
}

.header_details div li {
  background: #f5f6f6;
  border-radius: 12px;
  padding: 4px 8px 4px 8px;
  width: 43px;
}
.content {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: 40px;
}
.text_content {
  width: 70%;
}
.text_content {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.text_content div {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.details {
  margin-bottom: 0 !important;
}

.service_item {
  display: flex;
  flex-direction: row !important;
  justify-content: space-between;
}

.service_item li {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}
.date p {
  font-weight: 500;
  size: 18px;
}

.date p span {
  font-weight: 400;
  font-size: 18px;
}
.service p {
  width: 91px;
  height: 22px;
  top: 730px;
  left: 925px;
  gap: 0px;
  opacity: 0px;
}
.price {
  display: flex;
  align-items: center;
  color: black;
}

.price p {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}

.price p span {
  font-size: 18px;
  font-weight: 500;
  line-height: 28.13px;
}
.network {
  color: white;
  display: flex;
  flex-direction: row;
}

.network a,
.network p {
  color: white;
}

.network a:hover {
  text-decoration: underline;
}

.host_text h5 {
  font-size: 36px;
  margin-bottom: 14px;
}
.host_text p {
  font-weight: 500;
}

.block {
  min-height: 233px;
  margin-bottom: 40px;
}
.block_left {
  width: 60%;
  justify-content: center;
  align-items: center;
  gap: 40px;
  background-color: rgba(245, 246, 246, 1);
}
.block_left img {
  width: 150px;
}
.block_right {
  width: 45%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  background-color: rgb(39, 39, 39);
  padding-left: 80px;
  padding-right: 20px;
  margin-left: -30px;
  border-radius: 0px 26px 26px 0px;
  text-align: center;
}
.block_right h5,
.block_right p {
  text-align: center;
  width: 100%;
}
.block_right h5 {
  font-size: 36px;
  margin-bottom: 14px;
}

@media screen and (max-width: 1000px) {
  .content {
    width: 100%;
    flex-direction: column;
  }
  .block_left {
    width: 55%;
  }
  .block_right {
    width: 45%;
    padding-left: 60px;
  }
  .host_text h5 {
    font-size: 30px;
  }
  .block_right h5 {
    font-size: 30px;
  }
  .text_content {
    width: 100%;
  }
}

@media screen and (max-width: 850px) {
  .block {
    flex-direction: column;
  }
  .block_right,
  .block_left {
    width: 100%;
    margin-left: 0px;
    border-radius: 25px;
  }
  .block_right {
    margin-top: -40px;
    padding-top: 55px;
    padding-bottom: 15px;
    padding-left: 20px;
    gap: 0px;
    margin-left: 0px;
  }
}

@media screen and (max-width: 600px) {
  .header_details {
    margin-top: 20px;
  }
}

@media screen and (max-width: 550px) {
  .block_left {
    flex-direction: column;
    align-items: start;
    gap: 20px;
  }
  .block_left img {
    width: 90px;
  }
}
