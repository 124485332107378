.one_input {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 18px;
}
.social_title {
  width: 30%;
  font-size: 16px;
}

.one_input p {
  align-self: center;
}
.one_input input {
  border: none;
  outline: none;
  font-size: 16px;
}
.one_input button {
  padding: 2px 35px;
  font-size: 16px;
  min-height: 60px;
}

@media screen and (max-width: 1100px) {
  .one_input {
    width: 100%;
  }
}
@media screen and (max-width: 900px) {
  .one_input input {
    width: 30%;
  }
}
@media screen and (max-width: 768px) {
  .social_title {
    font-size: 18px !important;
    margin-bottom: 0px !important;
  }
  .one_input input {
    margin-bottom: 0px !important;
  }
}
@media screen and (max-width: 540px) {
  .one_input {
    width: 100%;
  }
}
@media screen and (max-width: 410px) {
  .one_input button {
    padding: 0px 22px;
    font-size: 14px;
    min-height: 50px;
  }
}
@media screen and (max-width: 410px) {
  .one_input input {
    width: 40%;
  }
  .social_title {
    font-size: 14px !important;
  }
}
