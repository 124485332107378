.img {
  width: 543px;
  height: 618px;
  border-radius: 26px;
  object-fit: cover;
}
.container {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-bottom: 40px;
  gap: 40px;
}

.steps_container {
  background-color: #f5f6f6;
  padding: 40px 30px 40px 30px;
  gap: 24px;
  height: auto;
  border-radius: 26px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}
.steps_container h5 {
  margin-bottom: 16px;
}
.steps_container .number {
  width: 60px;
  height: 60px;
  font-size: 20px;
  font-family: Roboto;
  font-size: 26px;
  font-weight: 500;
  line-height: 30.47px;
  text-align: center;
  background-color: white;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
}
.styles.steps_container_right {
  display: flex;
}
.text {
  width: 90%;
}

@media screen and (max-width: 1100px) {
  .img {
    width: 405px;
    height: 540px;
  }
}

@media screen and (max-width: 850px) {
  .img {
    width: 232px;
    height: 350px;
  }
  .text h5 {
    font-size: 22px;
  }
}

@media screen and (max-width: 600px) {
  .img {
    display: none;
  }
  .steps_container {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 400px) {
  .steps_container {
    flex-direction: column;
    padding: 30px 20px 30px 20px;
  }
  .text {
    width: 100%;
  }
}
