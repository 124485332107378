.nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 53px;

  margin: 20px auto 0 !important;

  margin: 18px auto 0;
  max-width: 1280px;

  margin: 18px auto 0 !important;

  width: 100%;
  z-index: 20;
}
.nav .buttons,
.nav ul,
.nav div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
}

@media screen and (max-width: 1000px) {
  .nav {
    margin: 0 24px;
    width: 96%;
  }
}
.nav button {
  font-size: 18px;
}

.burger_menu,
.burger_menu_2 {
  display: none !important;
}

.open_menu {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: rgba(245, 246, 246, 1);
  z-index: 10;
  pointer-events: all;
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.5s ease, transform 0.5s ease;
  display: none;
}
.menu {
  opacity: 0;
  height: 0;
  transform: translateY(-20px);
  pointer-events: none;
}
.open_menu ul li {
  font-size: 40px;
  font-weight: 500;
  margin-bottom: 20px;
}
.open_menu ul li:hover {
  text-decoration: underline;
}

@media screen and (max-width: 910px) {
  #auth_nav {
    display: none;
  }
  .burger_menu_2 {
    display: inline-block !important;
  }
  .open_menu {
    display: block;
  }
  .open_menu ul {
    margin-top: 140px;
  }
}

@media screen and (max-width: 850px) {
  .nav ul {
    margin-left: 40px;
    gap: 20px;
  }
  .nav .buttons {
    gap: 10px;
  }
}

@media screen and (max-width: 768px) {
  .nav .buttons,
  .nav ul {
    display: none;
  }
  .burger_menu {
    display: inline-block !important;
  }
  .open_menu {
    display: block;
  }
}

@media screen and (max-width: 400px) {
  .open_menu ul li {
    font-size: 32px;
  }
}

