.images {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 20px;
}

.bg {
  background-color: #f5f6f6;
  min-height: 200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
