.title {
  margin-top: 40px;
  margin-bottom: 40px;
}
.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-bottom: 180px;
  align-items: flex-start;
}

.container_left {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: calc(100% - ((100% / 3)));
}
.container_left_top {
  display: flex;
  width: 100%;
  margin-bottom: 24px;
}
.sub,
.trial {
  width: 50%;
  height: 310px;
  border-radius: 26px;
}
.sub {
  margin-right: 12px;
  background-color: #222222;
}
.trial {
  margin: 0 12px;
  background-color: #f5f6f6;
}
.content {
  border-radius: 26px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.lease {
  width: 100%;
  background-color: #f5f6f6;
  height: 284px;
  margin-right: 12px;
}

.img {
  width: calc((100% / 3) - 12px);
  border-radius: 26px;
  min-height: 618px;
  object-fit: cover;
}
.icon {
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border-radius: 16px;
}

@media screen and (max-width: 1000px) {
  .lease {
    height: 250px;
  }
  .sub,
  .trial {
    height: 350px;
  }
  .sub {
    margin-right: 0;
  }
  .container_left_top {
    margin-bottom: 12px;
  }
  .img {
    width: calc((100% / 3));
  }
}

@media screen and (max-width: 768px) {
  .container {
    flex-direction: column;
  }
  .container_left {
    width: 100%;
  }
  .content {
    height: 320px;
  }
  .lease {
    width: 49%;
  }
  .trial {
    margin-right: 0;
  }
  .img {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 49%;
    min-height: 320px;
  }
}

@media screen and (max-width: 600px) {
  .title {
    margin-top: 80px;
  }
  .container_left_top {
    flex-direction: column;
  }
  .content {
    width: 100%;
    height: 310px;
    margin: 10px 0;
  }
  .container {
    height: 1390px;
    margin-bottom: 100px;
  }
  .img {
    width: 100%;
    bottom: 0px;
  }
}

@media screen and (max-width: 500px) {
  .container {
    height: 1340px;
  }
  .content {
    padding: 20px;
  }
}
