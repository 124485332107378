.content_wrapper {
  max-width: 894px;
  min-height: 413px;
  border-radius: 26px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 100px 187px;
  background-color: white !important;
  position: relative;
  z-index: 2;
}

.content_wrapper p,
.content_wrapper h3 {
  text-align: center;
}
.content_wrapper button:first-of-type {
  padding: 18px;
}
.buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  gap: 12px;
}
