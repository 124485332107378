.footer {
  min-height: 110px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 26px;
  background-color: #f5f6f6;
  padding: 40px 30px;
  margin-bottom: 24px !important;
  margin-top: 2rem; /* Adjust this value to increase or decrease the gap */
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.footer div,
.footer ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
}

@media screen and (max-width: 1200px) {
  .footer {
    min-height: 150px;
    gap: 30px;
  }
  .footer ul {
    gap: 25px;
    flex-wrap: wrap;
    justify-content: center;
  }
}

@media screen and (max-width: 800px) {
  .footer {
    flex-direction: column;
  }
  .footer ul {
    flex-direction: column;
  }
}
