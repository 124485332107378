.content_wrapper {
  width: 50%;
  height: 100%;
  padding: 40px 0;
  background: white;
  animation: sideIn 1s;
  position: absolute;
  right: 0;
}

@keyframes sideIn {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

.content {
  position: relative;
  width: 85%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.close_icon {
  position: absolute;
  top: 20px;
  right: 0px;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid black;
  border-radius: 50%;
  transition: 0.3s;
  cursor: pointer;
}

.close_icon:hover {
  transition: 0.3s;
  background-color: rgb(240, 240, 240);
}

.errors {
  position: absolute;
  width: 100%;
  top: 0;
  padding: 45px 0px;
  background-color: #f5f6f6;
  z-index: 30;
}
.errors div {
  width: 85%;
  margin: 0 auto;
  font-weight: 500;
}

.content_block {
  width: 70%;
  margin-bottom: 50px;
}

.content_block h5 {
  font-size: 50px;
}
.inputs {
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.check_block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}

.check_block p:hover {
  text-decoration: underline;
  cursor: pointer;
}

.check {
  display: flex;
  gap: 8px;
}
.check input {
  cursor: pointer;
}

.link {
  display: flex;
  gap: 5px;
}

@media screen and (max-width: 1000px) {
  .content_wrapper {
    width: 70%;
  }
}

@media screen and (max-width: 768px) {
  .content_wrapper {
    width: 85%;
  }
  .content_block {
    width: 90%;
  }
  .content_block h5 {
    font-size: 40px;
  }
  .check label {
    font-size: 14px;
  }
}

@media screen and (max-width: 500px) {
  .content_wrapper {
    width: 100%;
  }
  .content_block {
    width: 100%;
  }
}
