.main_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 40px auto;
  gap: 62px;
}
.main_wrapper .images {
  min-width: 690px;
  max-width: 690px;
  min-height: 742px;
  max-height: 742px;
}
.main_left {
  min-width: 40%;
  min-height: 742px;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 85px;
}

.main_left h2 {
  display: flex;
}

.main_left div:first-child {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 21px;
}

.inputs_wrapper {
  display: flex;
  flex-direction: column;
  gap: 18px;
  width: 100%;
}

.inputs_wrapper .error-text {
  margin-top: -10px;
  margin-bottom: 5px;
}

.inputs {
  display: flex;
  gap: 12px;
  width: 100%;
}

.one_input {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.one_input p {
  align-self: center;
}
.one_input input {
  border: none;
  outline: none;
  font-size: 16px;
}

.inputs_errors {
  width: 100%;
  display: flex;
  gap: 12px;
}

.input_step_wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 20%;
}

.step2_wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
  min-height: 400px;
}

.titles {
  width: 70%;
}
.step3_second_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20%;
}
.step3_radio {
  display: flex;
  flex-direction: column;
  gap: 21px;
}

.step3_radio div {
  display: flex;
  width: 100%;
  gap: 8px;
  cursor: pointer;
}

.step3_radio div:first-child {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  gap: 8px;
  cursor: pointer;
}
.step4_wrapper {
  width: 100%;
}
.step4_second_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20%;
  width: 100%;
}
.step4_second_wrapper div {
  width: 100%;
}
.main_wrapper_children {
  min-width: 50%;
  width: 100%;
}
.step4_inputs {
  width: 100%;
}
.check {
  display: flex;
  gap: 8px;
  margin-top: 10px;
  align-items: center;
}
.immages_uploader {
  max-width: 200px;
  min-width: 200px;
  border-radius: 15px;
}
.main_left .uploader {
  width: 100%;
  height: 170px;
  background-color: #f5f6f6;
  border-radius: 33px;
  padding: 16px 20px 16px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #7c7c7c;
}

.social_title {
  font-size: 36px !important;
}
.step1_titles h5:nth-of-type(2) {
  font-size: 28px;
}
.step1_titles p {
  width: 80%;
  font-size: 16px;
}

@media screen and (max-width: 1380px) {
  .main_wrapper .images {
    border-radius: 26px;
    min-width: 580px;
    max-width: 580px;
    object-fit: cover;
  }
  .main_left {
    width: 45%;
  }
}
@media screen and (max-width: 1250px) {
  .main_wrapper .images {
    min-width: 440px;
    max-width: 440px;
  }
  .main_left {
    width: 60%;
  }
}

@media screen and (max-width: 1000px) {
  .main_wrapper .images {
    min-width: 385px;
    max-width: 385px;
    min-height: 720px;
    max-height: 720px;
  }
  .main_wrapper_children {
    margin-top: 60px;
  }
  .social_wrapper {
    margin-top: -70px;
  }
}

@media screen and (max-width: 930px) {
  .step2_wrapper h3 {
    font-size: 60px;
  }
}

@media screen and (max-width: 840px) {
  .main_wrapper .images {
    min-width: 285px;
    max-width: 285px;
    min-height: 720px;
    max-height: 720px;
  }
}

@media screen and (max-width: 768px) {
  .main_wrapper .images {
    display: none;
  }
  .main_wrapper_children {
    width: 80%;
    margin: 40px auto 0px;
  }
  .main_left {
    width: 100%;
  }
  .step2_wrapper h5 {
    font-size: 32px;
    margin-bottom: 20px;
  }
  .step2_wrapper p {
    font-size: 14px;
  }
  .social_title {
    font-size: 32px !important;
    margin-bottom: 5px !important;
  }
  .step1_titles h5:nth-of-type(2) {
    font-size: 24px;
    margin-bottom: 5px;
  }
}

@media screen and (max-width: 500px) {
  .main_wrapper_children {
    width: 100%;
  }
}

@media screen and (max-width: 400px) {
  .step2_wrapper h3 {
    font-size: 50px;
  }
}

.header {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

@media screen and (max-width: 768px) {
  .header {
    padding: 0 20px;
  }
}

.buttons {
  display: flex;
  align-self: flex-start;
  gap: 10px;
  margin-top: 20px;
}
