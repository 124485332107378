#grey_card {
  height: 440px;
}

.cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  margin-top: 40px;
}

.card {
  width: 23%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 26px;
}

.card div {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}

@media screen and (max-width: 1100px) {
  .card {
    width: 49%;
    margin-bottom: 40px;
  }
  #grey_card {
    height: 380px;
  }
}

@media screen and (max-width: 768px) {
  #grey_card {
    height: 320px;
  }
  #card_title {
    font-size: 20px;
  }
  #card_text {
    width: 95%;
    font-size: 14px;
  }
}

@media screen and (max-width: 500px) {
  .card {
    width: 100%;
  }
}
