.wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
  margin-right: 30px;
}

.main {
  position: relative;
  width: 482px;
  height: 600px;
}
.main img {
  height: 100%;
  object-fit: cover;
  cursor: pointer;
}

.arrow {
  width: 48px;
  height: 48px;
  background-color: rgba(255, 255, 255, 0.84);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  animation: opacity 0.3s;
  position: relative;
  z-index: 10;
}
.arrow_increase img {
  width: 15px;
  height: auto;
}
.arrow_switch img {
  width: 20px;
  height: auto;
}
.arrow:hover {
  background-color: #fff;
}
.arrow_increase {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10;
}

.arrow_switches {
  position: absolute;
  top: 48%;
  left: 10px;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 10;
}
.photo_number {
  position: absolute;
  bottom: 10px;
  right: 10px;
}
.dots {
  position: absolute;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  gap: 5px;
}
.dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.4);
  transition: 0.3s;
}

@keyframes opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.images {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 13%;
}

.images img {
  width: 118px;
  height: 142px;
  object-fit: cover;
  cursor: pointer;
  border-radius: 10px;
  transition: border 0.3s ease;
}

.modal {
  position: fixed;
  z-index: 50;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(40, 40, 40, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  transition: 0.3s;
}
.active {
  opacity: 1;
  pointer-events: all;
  transition: 0.3s;
}

.modal__item {
  position: relative;
  width: 90%;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal__item img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  border-radius: 25px;
  object-fit: contain;
}
.arrow_increase_modal {
  position: absolute;
  top: 30px;
  right: 30px;
}
.switches_modal {
  width: 95%;
  margin: 0 auto;
}

@media screen and (max-width: 1250px) {
  .main {
    width: 340px;
    height: 460px;
  }
  .images {
    display: none;
  }
  .modal__item {
    width: 90%;
  }
  .switches_modal {
    width: 95%;
  }
}

@media screen and (max-width: 1000px) {
  .wrapper {
    width: 100%;
  }
  .main {
    width: 100%;
    margin-right: 0;
    height: 580px;
    margin-bottom: 30px;
  }
  .modal__item {
    width: 90%;
  }
  .switches_modal {
    width: 95%;
  }
}

@media screen and (max-width: 768px) {
  .main {
    height: 800px;
  }
}

@media screen and (max-width: 650px) {
  .modal__item {
    width: 90%;
    height: 90%;
  }
  .switches_modal {
    width: 85%;
  }
  .arrow_increase_modal {
    top: 7%;
    right: 7%;
  }
}

@media screen and (max-width: 500px) {
  .main {
    height: 475px;
  }
  .modal__item {
    width: 90%;
    height: 80%;
  }
  .arrow_increase_modal {
    top: 2%;
    right: 7%;
  }
}

@media screen and (max-width: 375px) {
  .modal__item {
    height: 70%;
  }
}

.video_container, .modal_video_container {
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  z-index: 1;
}

.video, .modal_video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  position: relative;
  z-index: 1;
}

.video_placeholder {
  width: 100%;
  height: 100%;
  position: relative;
  cursor: pointer;
}

.video_thumbnail {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.play_button_overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  padding: 15px;
}


.video_placeholder {
  position: relative;
  cursor: pointer;
}

.play_overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  pointer-events: all;
  cursor: pointer;
}

.play_text {
  cursor: pointer;
}


.play_text {
  color: white;
  font-size: 16px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.modal_video_container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal_video {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.modal_video_thumbnail {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.video_placeholder {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
