.container h3 {
  margin: 40px 0 20px;
}

.filters {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 15px;
  margin: 60px 0 40px;
  transition: all 0.3s ease;
}

.filter {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.filter input,
.filter select,
.filter .datePicker {
  position: relative;
  width: 100%;
  height: 68px;
  gap: 10px;
  border: none;
  font-size: 22px;
  color: rgba(137, 135, 135, 1);
  background-color: rgba(245, 246, 246, 1);
  padding: 8px 25px;
  border-radius: 33px;
  cursor: pointer;
}
.direction_input {
  width: 27%;
}
.date_input {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.select_input {
  width: 20%;
}
.datePicker:active {
  outline: none;
}
.datePicker:focus {
  outline: none;
}
.filter select {
  position: relative;
  padding-top: 28px;
  padding-left: 20px;
}
.filter select:focus {
  outline: none;
}
.filter input,
.filter .datePicker {
  padding-top: 28px;
}

.filter label {
  font-size: 16px;
  position: absolute;
  top: 8px;
  left: 25px;
  z-index: 2;
}

.arrow_icon {
  position: absolute;
  top: 6px;
  right: 7px;
  width: 52px;
  height: 52px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(255, 255, 255);
  cursor: pointer;
}

.bookNowButton {
  padding: 10px 20px;
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.bookNowButton:hover {
  background-color: #333;
}

.filter_info {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 30px auto;
  cursor: pointer;
  background-color: rgba(245, 246, 246, 1);
  padding: 15px 25px;
  border-radius: 33px;
}
.filter_info p {
  align-self: center;
  margin: 0;
}
.filter_info img {
  display: none;
  transition: transform 0.3s ease;
}

.filter_info img.rotated {
  transform: rotate(180deg);
}

.collapsed {
  height: 0;
  overflow: hidden;
  margin: 0;
  padding: 0;
  opacity: 0;
}

.open_menu {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0px;
  background-color: rgba(245, 246, 246, 1);
  z-index: 30;
  pointer-events: all;
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.5s ease, transform 0.5s ease;
  display: none;
}
.menu {
  opacity: 0;
  height: 0;
  transform: translateY(-20px);
  pointer-events: none;
}
.open_menu ul li {
  font-size: 40px;
  font-weight: 500;
  margin-bottom: 20px;
}
.open_menu ul li:hover {
  text-decoration: underline;
}
.close_icon {
  position: absolute;
  top: 25px;
  right: 25px;
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.search_button {
  background-color: black;
  color: white;
  border: none;
  border-radius: 55px;
  padding: 12px 24px;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.2s ease;
}

.search_button:hover {
  background-color: #333;
}

.filter_info img {
  display: block;
}

@media screen {
  .direction_input {
    width: 100%;
  }
  .date_input {
    width: 23%;
  }
  .select_input {
    width: 30%;
  }
  .search_button {
    width: 20%;
  }
}

@media screen and (max-width: 1000px) {
  .date_input {
    width: 24%;
  }
  .select_input {
    width: 27%;
  }
}

@media screen and (max-width: 900px) {
  .filters {
    justify-content: center;
    gap: 5px;
  }
  .filter {
    margin-bottom: 15px;
  }
  .date_input {
    width: 32%;
  }
  .select_input {
    width: 32%;
  }
  .open_menu {
    display: block;
  }
  .open_menu ul {
    margin-top: 40px;
  }
}

@media screen and (max-width: 815px) {
  .date_input {
    width: 31%;
  }
  .select_input {
    width: 36%;
  }
  .filter label {
    font-size: 14px;
  }
  .filter input,
  .filter select {
    font-size: 20px;
    background-color: transparent;
  }
  .filter {
    border: 1px solid black;
    border-radius: 55px;
  }
  .filter .datePicker{
    font-size: 20px;
    background-color: transparent;
    border: none;
    border-radius: 55px;
  }
  /* .datePicker {
    background-color: transparent;
  } */

  .filters {
    display: flex;
    transition: all 0.3s ease;
  }
  
  .collapsed {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .burger_menu {
    display: inline-block !important;
  }
  .open_menu {
    display: block;
  }
}
@media screen and (max-width: 650px) {
  .filters {
    display: block;
  }
  .filter_info img {
    display: block;
    cursor: pointer;
  }
  .filter_info p {
    font-size: 14px;
  }
  .direction_input,
  .date_input,
  .select_input {
    width: 100%;
  }
  .filter input,
  .filter select,
  .date_input {
    border-radius: 55px;
  }
  .container h3 {
    margin: 30px 0 20px;
  }
}
@media screen and (max-width: 400px) {
  .open_menu ul li {
    font-size: 32px;
  }
}

.datePicker {
  width: 100%;
  margin-left: 0;
}