.wrapper {
  margin: auto;
  max-width: 845px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 54px;
  margin-bottom: 180px;
  margin-top: 83px;
}
