.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 180px auto !important;
  gap: 26px;
  background-color: #f5f6f6;
  padding: 100px;
  border-radius: 26px;
}

.wrapper p {
  text-align: center;
  margin: auto;
  width: 35%;
  margin-bottom: 14px;
}

@media screen and (max-width: 768px) {
  .wrapper p {
    width: 70%;
  }
  .wrapper {
    margin: 100px auto !important;
  }
}
@media screen and (max-width: 500px) {
  .wrapper p {
    width: 100%;
  }
  .wrapper {
    padding: 60px 30px;
    margin: 70px auto !important;
  }
}
