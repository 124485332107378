.card {
  display: flex;
  margin-top: 34px;
  gap: 34px;
}

.travel_titles {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.travel_titles p {
  align-self: center;
  width: 30%;
  text-align: center;
}

#grey_card {
  height: 429px;
}

@media screen and (max-width: 1000px) {
  #grey_card {
    height: 400px;
  }
  .travel_titles p {
    width: 50%;
  }
}
@media screen and (max-width: 768px) {
  #grey_card {
    height: 340px;
  }
  #grey_card p {
    font-size: 14px;
  }
  .card {
    gap: 15px;
  }
  .travel_titles p {
    font-size: 14px;
  }
}

@media screen and (max-width: 600px) {
  .card {
    flex-direction: column;
  }
  .card section:first-of-type {
    height: 370px !important;
  }
  #grey_card {
    height: 250px;
  }
  .travel_titles p {
    width: 90%;
  }
}
