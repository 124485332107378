.hero {
  display: flex;
  width: 100%;
  gap: 24px;
  margin: 18px auto 0 !important;
}

.button_group {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

#hero_card {
  align-items: flex-start;
}
#hero_card_title {
  margin-bottom: 24px;
}
#hero_card_text {
  width: 66%;
  margin-bottom: 30px;
}
.text {
  margin-left: 20px;
}
.lesor_right {
  width: 50%;
  margin-left: -30px;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  background-color: rgba(40, 40, 40, 0.84);
}

@media screen and (max-width: 1080px) {
  .lesor {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .lesor div {
    width: 100%;
    margin-left: 0;
    border-radius: 26px;
  }
  .lesor > div:nth-child(2) {
    margin-top: -50px;
  }
  .text {
    margin-top: 40px;
  }
}

@media screen and (max-width: 1000px) {
  #hero_card,
  #hero_img_card {
    height: 540px;
  }
  #hero_card_title {
    margin-bottom: 5px;
    line-height: 72px;
  }
  #hero_card_text {
    width: 100%;
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 880px) {
  #hero_card_title {
    font-size: 60px;
    line-height: 64px;
  }
  #hero_card_text {
    font-size: 14px;
  }
}

@media screen and (max-width: 660px) {
  .hero {
    gap: 12px;
  }
  #hero_card,
  #hero_img_card {
    padding: 12px;
  }
}

@media screen and (max-width: 600px) {
  #hero_img_card {
    display: none;
  }
  #hero_card {
    align-items: center;
  }
  #hero_card_text {
    width: 70%;
  }
}
@media screen and (max-width: 460px) {
  #hero_card_text {
    width: 80%;
  }
}
