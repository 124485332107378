/** Sidebar **/
.left_side {
  position: relative;
  width: 18%;
}
.user_block {
  margin-bottom: 70px;
}
.user_avatar {
  display: flex;
  margin-bottom: 16px;
}
.user_avatar img {
  width: 160px;
  height: 160px;
  border-radius: 50%;
  object-fit: cover;
}
.user_avatar span {
  font-weight: 500;
  font-size: 30px;
  margin-top: -20px;
  cursor: pointer;
}
.list li {
  font-weight: 500;
  font-size: 30px;
  margin-bottom: 10px;
  cursor: pointer;
}

.right_side {
  width: 75%;
}
.logout_btn {
  position: absolute;
  width: 100%;
  font-size: 20px !important;
}
.back {
  display: none;
  padding: 6px 14px;
  border-radius: 25px;
  border: none;
  background-color: rgba(245, 246, 246, 1);
  font-size: 18px;
  align-items: center;
  gap: 10px;
  margin-bottom: 30px;
}
.back div {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: rgb(255, 255, 255);
}

/** My ads **/

.myads_title {
  display: none;
}

.ads_header {
  display: flex;
  border-bottom: 1px solid #d7d7d7;
  cursor: pointer;
}

.active_link,
.inactive_link {
  cursor: pointer;
  width: 22%;
  padding-bottom: 20px;
  transition: border-bottom 0.3s ease, transform 0.3s ease;
}

.active_link {
  border-bottom: 2px solid black;
}

.inactive_link {
  border-bottom: 2px solid transparent;
}

.ads_filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.filters {
  display: flex;
  gap: 10px;
  margin-top: 30px;
}

.filter {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.filter input,
.filter select,
.filter .datePicker {
  width: 200px;
  height: 48px;
  gap: 10px;
  border: none;
  font-size: 24px;
  color: rgba(137, 135, 135, 1);
  cursor: pointer;
}
.datePicker:active {
  outline: none;
}
.datePicker:focus {
  outline: none;
}
.datePicker::placeholder {
  position: absolute;
  bottom: 0;
}

.filter label {
  font-size: 16px;
  position: absolute;
  left: 0px;
  z-index: 2;
}

.arrow_icon {
  position: absolute;
  right: 20px;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(245, 246, 246, 1);
  cursor: pointer;
  /*pointer-events: none;*/
}

.search_btn_block {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-top: 30px;
}

.search_btn {
  width: 48px;
  height: 48px;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
}

.ads_list {
  margin-top: 40px;
}
.card {
  display: flex;
  justify-content: space-between;
  height: 340px;
  border-radius: 25px;
  background-color: #f5f6f6;
  margin-bottom: 40px;
  transition: all 0.3s;
}

.card:hover {
  transform: scale(0.95);
  filter: brightness(80%);
}
.img_wrapper {
  width: 42%;
  height: auto;
  cursor: pointer;
  object-fit: cover;
}

.images {
  width: 42%;
  border-radius: 25px;
  object-fit: cover;
}
.img_wrapper img {
  width: 100%;
  border-radius: 25px;
  object-fit: cover;
}
.not_img {
  width: 42%;
  height: auto;
  background-color: rgb(201, 201, 201);
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card_content_info h5:hover {
  text-decoration: underline;
}
.card_content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 54%;
  padding: 30px;
}

.card_content ul {
  align-self: flex-end;
  display: flex;
  gap: 20px;
}
.card_content ul li {
  cursor: pointer;
}
.edit_icon {
  display: none;
}
.card_content_info {
  margin-top: 10%;
}
.card_content_info h5 {
  text-transform: uppercase;
  margin-bottom: 18px;
}

.card_content_text {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 18px;
}

.card_content_text p {
  display: flex;
}
.date {
  border-radius: 12px;
  padding: 4px 8px;
  background-color: white;
  width: 80%;
}

@media screen and (max-width: 1300px) {
  .active_link,
  .inactive_link {
    width: 25%;
  }
}
@media screen and (max-width: 1150px) {
  .left_side {
    width: 22%;
  }
  .active_link,
  .inactive_link {
    width: 28%;
  }
  .card_content {
    padding-left: 0px;
  }
}
@media screen and (max-width: 1000px) {
  .right_side {
    width: 75%;
  }

  .user_avatar img {
    width: 140px;
    height: 140px;
  }
  .user_block h5 {
    font-size: 18px;
  }
  .active_link,
  .inactive_link {
    width: 34%;
  }
  .ads_header h5 {
    font-size: 22px;
  }
  .filter label {
    font-size: 14px;
  }
  .datePicker::placeholder {
    font-size: 20px;
  }
  .filter input,
  .filter select,
  .filter .datePicker {
    width: 180px;
    height: 44px;
  }
  .arrow_icon {
    width: 44px;
    height: 44px;
  }
  .btn_text_sort {
    font-size: 14px !important;
  }
  .btn_text_date {
    font-size: 18px !important;
  }
  .search_btn {
    width: 44px;
    height: 44px;
  }
  .card {
    height: auto;
  }
  .card_content {
    width: 55%;
  }
  .card_content_info {
    margin: 20px 0;
  }
  .card_content h5 {
    font-size: 20px;
  }
}

@media screen and (max-width: 850px) {
  .left_side {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 30px;
    margin-bottom: 110px;
  }
  .logout_btn {
    position: absolute;
    width: 100%;
    top: 190px;
    display: flex !important;
  }
  .user_block {
    margin-bottom: 0;
  }
  .user_avatar img {
    width: 110px;
    height: 110px;
  }
  .active_li,
  .inactive_li {
    cursor: pointer;
    width: 25%;
    padding-bottom: 20px;
    transition: border-bottom 0.3s ease, transform 0.3s ease;
  }
  .active_li {
    border-bottom: 2px solid black;
  }
  .inactive_li {
    border-bottom: 2px solid rgba(0, 0, 0, 0);
  }
  .list {
    width: 70%;
    display: flex;
    align-items: end;
    border-bottom: 1px solid #d7d7d7;
  }
  .list li {
    margin-bottom: 0;
    font-size: 24px;
  }
  .exit {
    display: none;
  }
  .right_side {
    width: 100%;
  }
  .active_link,
  .inactive_link {
    width: 27%;
  }
}

@media screen and (max-width: 720px) {
  .user_block h5 {
    font-size: 16px;
  }
  .list {
    width: 68%;
  }
  .list li {
    font-size: 22px;
  }
  .active_li,
  .inactive_li {
    width: 28%;
    padding-bottom: 15px;
  }
  .ads_header h5 {
    font-size: 20px;
  }
  .card_content li {
    font-size: 15px;
  }
  .card_content h5 {
    font-size: 18px;
    margin-bottom: 12px;
  }
  .card_content p {
    font-size: 14px;
  }
  .card_content_info {
    margin: 20px 0px 0px;
  }
  .filter label {
    font-size: 13px;
  }
  .datePicker::placeholder {
    font-size: 18px;
  }
  .btn_text_sort {
    font-size: 13px !important;
  }
  .btn_text_date {
    font-size: 17px !important;
  }
}
@media screen and (max-width: 660px) {
  .active_link,
  .inactive_link {
    width: 32%;
  }
  .ads_filter {
    flex-wrap: wrap;
  }
  .filters {
    width: 100%;
    justify-content: space-between;
  }
  .card {
    flex-direction: column;
  }
  .images {
    width: 100%;
    height: 340px;
  }
  .not_img {
    width: 100%;
    height: 340px;
  }
  .card_content {
    width: 100%;
    padding: 20px;
  }
  .card_content_info {
    margin-top: -15px;
  }
  .card_content_info h5 {
    width: 75%;
  }
}

@media screen and (max-width: 640px) {
  .list {
    width: 66%;
  }
  .active_li,
  .inactive_li {
    width: 32%;
  }
}

@media screen and (max-width: 570px) {
  .hide_list {
    display: none;
  }
  .myads_title {
    display: block;
    font-size: 38px;
    margin-bottom: 30px;
  }
  .left_side {
    flex-direction: column;
  }
  .logout_btn {
    position: relative;
    top: -30px;
  }
  .user_block {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 15px;
  }
  .user_avatar span {
    position: absolute;
    right: 0;
    top: 32%;
    font-size: 46px;
  }
  .list {
    width: 100%;
    border-bottom: 0px;
    display: block;
  }
  .list li {
    font-size: 32px;
  }
  .active_li,
  .inactive_li {
    width: 100%;
  }
  .active_li {
    border-bottom: 0px;
  }
  .back {
    display: flex;
  }
}
@media screen and (max-width: 520px) {
  .active_link,
  .inactive_link {
    width: 36%;
  }
}
@media screen and (max-width: 460px) {
  .active_link,
  .inactive_link {
    width: 42%;
  }
  .images {
    height: 280px;
  }
  .not_img {
    height: 280px;
  }
}
@media screen and (max-width: 400px) {
  .user_avatar img {
    width: 90px;
    height: 90px;
  }
  .user_block {
    gap: 10px;
  }
  .user_block h5 {
    font-size: 14px;
  }
  .user_avatar span {
    position: absolute;
    right: 0;
    top: 34%;
    font-size: 38px;
  }
  .ads_header h5 {
    font-size: 18px;
  }
  .active_link,
  .inactive_link {
    width: 50%;
  }
  .filter input,
  .filter select,
  .filter .datePicker {
    width: 145px;
    height: 44px;
  }
  .arrow_icon {
    right: 0px;
  }
  .card_content ul {
    display: none;
  }
  .edit_icon {
    position: absolute;
    top: 30px;
    right: 20px;
    display: block;
    width: 16px;
  }
  .card_content_info {
    margin-top: 10px;
  }
  .card_content_info h5 {
    width: 85%;
  }
}

/** My profile **/
.info_wrapper {
  width: 65%;
}
.personal_info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 55px;
}
.personal_info_text {
  width: 50%;
}
.personal_info_text h5 {
  margin-bottom: 18px;
}
.personal_info_text input {
  margin-bottom: 12px;
}
.personal_info_avatar {
  position: relative;
}
.user_img {
  width: 160px;
  height: 160px;
  border-radius: 50%;
  object-fit: cover;
}
.user_img_icon {
  position: absolute;
  right: 10px;
  bottom: 0px;
  cursor: pointer;
}

.personal_info_item {
  margin-bottom: 55px;
}
.personal_info_item h5 {
  margin-bottom: 18px;
}
.personal_info_item input {
  margin-bottom: 12px;
}

.radio {
  display: flex;
  gap: 40px;
}

.radio div {
  display: flex;
  cursor: pointer;
  gap: 10px;
}

.radio div:first-child {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 8px;
  cursor: pointer;
}

.check {
  display: flex;
  gap: 8px;
}

@media screen and (max-width: 1260px) {
  .info_wrapper {
    width: 80%;
  }
}
@media screen and (max-width: 900px) {
  .info_wrapper {
    width: 100%;
  }
}
@media screen and (max-width: 500px) {
  .right_side h3 {
    font-size: 36px;
  }
  .personal_info {
    flex-direction: column-reverse;
    align-items: start;
  }
  .personal_info_avatar {
    margin-bottom: 30px;
  }
  .personal_info_text {
    width: 100%;
  }
}

/** Subscription block **/
.card_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 40px;
  border-radius: 25px;
  background-color: #f5f6f6;
}
.card_info {
  width: 40%;
  margin: 10px 0px;
}
.card_info button {
  margin-top: 20px;
}
.card_info_text {
  margin-bottom: 45px;
}
.card_info_title {
  margin-bottom: 30px;
}
.card_block {
  width: 320px;
  height: 220px;
  padding: 30px;
  margin: 10px 45px;
  display: flex;
  flex-direction: column;
  justify-content: end;
  border-radius: 25px;
  background-color: #e6e6e6;
}
.card_block p {
  font-weight: 500;
}
.subscription_btn {
  margin-top: 50px;
}

@media screen and (max-width: 1160px) {
  .card_wrapper {
    padding: 40px 20px;
  }
  .card_block {
    margin: 10px 0px;
  }
  .card_info {
    width: 45%;
  }
}

@media screen and (max-width: 970px) {
  .card_block {
    width: 300px;
    height: 200px;
  }
  .card_info h5 {
    font-size: 20px;
  }
  .card_info p {
    font-size: 15px;
  }
  .card_info button {
    font-size: 20px;
  }
}

@media screen and (max-width: 650px) {
  .card_wrapper {
    position: relative;
  }
  .card_info {
    width: 80%;
  }
  .card_info_text {
    margin-bottom: 30px;
  }
  .card_info button {
    margin-top: 30px;
  }
  .card_block {
    position: absolute;
    right: 15px;
    width: 160px;
    height: 70px;
    padding: 0px 0px 0px 15px;
    margin-top: 30px;
    display: flex;
    justify-content: center;
  }
}
@media screen and (max-width: 500px) {
  .card_info_title {
    width: 50%;
  }
}
@media screen and (max-width: 400px) {
  .card_info {
    width: 100%;
  }
  .card_block {
    width: 150px;
    right: 10px;
  }
}
@media screen and (max-width: 375px) {
  .card_info_title {
    width: 35%;
  }
}

/** Success Popup **/
.success_popup {
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: #90EE90;
  padding: 15px 30px;
  border-radius: 8px;
  color: black;
  z-index: 1000;
  animation: slideIn 0.3s ease-out forwards;
  font-size: 18px;
  font-weight: 500;
}

.success_popup.hide {
  animation: slideOut 0.3s ease-out forwards;
}

@keyframes slideIn {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideOut {
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(-100%);
    opacity: 0;
  }
}
