.notfound h3,
.notfound p {
  display: block;
  width:fit-content;
  align-self: center;
  margin-bottom: 40px;
}
.notfound {
  height: calc(100vh - 53px);
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}
