.imagePreview {
  width: 193px;
  height: 228px;
  object-fit: cover;
  border-radius: 26px;
}
.uploader {
  width: 193px;
  height: 228px;
  background-color: #f5f6f6;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 26px;
}
.uploader input,
.uploader label {
  width: 193px;
  height: 228px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.imageGallery {
  display: flex;
  flex-wrap: wrap;
  gap: 14px;
  margin-bottom: 14px;
}
.imageContainer {
  position: relative;
  display: inline-block;
  cursor: move;
  transition: transform 0.2s ease;
}

.imageContainer:hover {
  transform: scale(1.02);
}

.imageContainer[draggable="true"]:active {
  cursor: grabbing;
}

.deleteButton {
  position: absolute;
  top: 7px;
  right: 7px;
  width: 20px;
  height: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  padding: 0;
  line-height: 1;
}

.deleteButton:hover {
  background-color: rgba(0, 0, 0, 0.7);
}
