.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.card img {
  width: 100px;
  border-radius: 50%;
  height: 100px; /* Adjust the size as needed */
  border-radius: 50%; /* Make the images circular */
  object-fit: cover; /* Ensure the images cover the area */
}

.wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 70px;
  margin-bottom: 40px;
  gap: 20px;
}
.address_wrapper {
  padding: 16px 22px;
}

#item_text {
  width: 45%;
  margin-bottom: 60px;
}

.accordion {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  flex: 1;
  margin: 0;
  padding: 0;
  transition: all 600ms cubic-bezier(0.075, 0.82, 0.165, 1);
  height: 510px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  border-radius: 26px;
  padding: 10px;
}

@media screen and (max-width: 1000px) {
  #item_text {
    width: 75%;
  }
  .wrapper {
    gap: 10px;
  }
  .accordion {
    height: 400px;
  }
  .address_wrapper {
    padding: 12px 14px;
  }
  .address_wrapper h5 {
    font-size: 16px;
  }
  .address_wrapper p {
    font-size: 14px;
  }
}
@media screen and (max-width: 768px) {
  .accordion {
    height: 320px;
  }
}
@media screen and (max-width: 650px) {
  .accordion {
    flex-basis: 100%;
  }
}
@media screen and (max-width: 500px) {
  #item_text {
    width: 100%;
  }
  .accordion {
    height: 290px;
  }
}

.accordion:hover {
  flex-basis: 30vw;
}

.title_wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 26px;
}
