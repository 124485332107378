.card {
  display: flex;
  flex-direction: column;
  width: 49%;
  background-color: #f5f6f6;
  border-radius: 26px;
}
.card img {
  object-fit: cover;
  width: 100%;
  height: 400px;
  border-radius: 30px;
}
.card div {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.card div div {
  flex-direction: column;
  gap: 10px;
}
.card div div p span {
  background-color: white;
  margin-left: 10px;
  border-radius: 12px;
  padding: 4px 8px 4px 8px;
}
.list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 24px;
}

.pagination {
  display: flex;
  justify-content: center;
  gap: 16px;
  margin: 40px auto;
}
.pagination_btn {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  border: none;
  background-color: #f5f6f6;
  font-size: 24px;
  cursor: pointer;
}
.pagination_btn:disabled {
  border: 1px solid #282828;
}

.further_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 64px;
  font-size: 24px;
  border-radius: 32px;
  border: none;
  padding: 0px 8px 0px 20px;
  cursor: pointer;
}
.further_btn::after {
  content: url(../../../assets/icons/arrow-toright.svg);
  margin-left: 12px;
  background-color: white;
  padding-bottom: 5px;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.prev_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 64px;
  font-size: 24px;
  border-radius: 32px;
  border: none;
  padding: 0 20px 0 0;
  cursor: pointer;
}

.prev_btn::before {
  content: url(../../../assets/icons/arrow-toright.svg);
  transform: rotate(180deg);
  margin-left: 12px;
  background-color: white;
  width: 48px;
  height: 48px;
  padding-bottom: 5px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 14px;
}
.card {
  cursor: pointer;
  overflow: hidden;
  transition: all 0.3s;
}

.card img {
  border-radius: 32px;
}
.card:hover {
  transform: scale(0.95);
  filter: brightness(80%);
}
.ellipsis {
  display: flex;
  align-items: flex-end;
}
.title {
  font-size: 18px;
  font-weight: 700;
}

.title:hover {
  text-decoration: underline;
}

@media screen and (max-width: 1310px) {
  .card {
    width: 48%;
  }
}
@media screen and (max-width: 1000px) {
  .card img {
    height: 325px;
  }
}
@media screen and (max-width: 768px) {
  .pagination {
    gap: 10px;
  }
  .card img {
    height: 290px;
  }
  .card p {
    font-size: 14px;
  }
  .pagination_btn {
    width: 50px;
    height: 50px;
    font-size: 20px;
  }
  .further_btn,
  .prev_btn {
    height: 50px;
    font-size: 20px;
  }
  .further_btn::after,
  .prev_btn::before {
    width: 38px;
    height: 38px;
  }
}

@media screen and (max-width: 655px) {
  .card {
    width: 47%;
  }
  .prev_btn,
  .further_btn {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    font-size: 0;
    padding: 5px;
  }
  .prev_btn::before,
  .further_btn::after {
    background-color: transparent;
  }
  .prev_btn::before {
    padding-bottom: 0;
    padding-right: 3px;
  }
  .further_btn::after {
    padding-top: 5px;
    padding-right: 10px;
  }
}

@media screen and (max-width: 550px) {
  .card {
    width: 100%;
  }
}

@media screen and (max-width: 400px) {
  .card {
    width: 100%;
  }
  .pagination_btn {
    width: 40px;
    height: 40px;
    font-size: 13px;
  }
  .further_btn,
  .prev_btn {
    width: 40px;
    height: 40px;
    padding: 10px 8px;
  }
  .open_menu ul li {
    font-size: 32px;
  }
}
