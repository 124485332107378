.container_one {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-bottom: 40px;
  align-items: flex-start;
  gap: 40px;
}
.container_one h3 {
  width: 60%;
}
.container_one_right {
  width: calc((100% / 3) - 12px);
  margin-top: 10px;
}
.card {
  width: 33%;
  border-radius: 26px;
  background-color: #f5f6f6;
}
.card > div {
  padding: 0 30px 30px 30px;
}

.card img {
  width: 100%;
  border-radius: 26px;
  height: 420px;
  object-fit: cover;
  margin-bottom: 30px;
}
.container_two {
  display: flex;
  gap: 24px;
}

@media screen and (max-width: 1000px) {
  .container_one h3 {
    width: 100%;
  }
  .container_one {
    flex-direction: column;
  }
  .container_one_right {
    width: 90%;
  }
  .card img {
    height: 380px;
  }
  .container_two {
    gap: 14px;
  }
}

@media screen and (max-width: 768px) {
  .container_two {
    flex-wrap: wrap;
  }
  .card {
    width: 48%;
  }
  .card img {
    height: 340px;
    margin-bottom: 18px;
  }
  .card h5 {
    font-size: 22px;
  }
  .card:last-of-type {
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .card {
    width: 100%;
  }
}

@media screen and (max-width: 400px) {
  .card > div {
    padding: 0 20px 20px 20px;
  }
}
