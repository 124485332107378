.wrapper {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-height: 100vh;
  min-height: 100vh;
  padding: 20px 0;
  width: 92%;
  gap: 62px;
}

.wrapper .images {
  min-width: 690px;
  max-width: 690px;
  min-height: 742px;
  max-height: 742px;
}

.left_block {
  position: relative;
  min-width: 40%;
  min-height: 742px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 85px;
}

.left_block_logo {
  position: absolute;
  top: 20px;
}

.content_block h5 {
  font-size: 50px;
  font-weight: 500;
  margin-bottom: 20px;
}
.content_block p {
  margin-bottom: 20px;
}

.inputs input {
  margin-bottom: 20px;
}

.check_block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}

.check_block p:hover {
  text-decoration: underline;
  cursor: pointer;
}

.check {
  display: flex;
  gap: 8px;
}
.check input {
  cursor: pointer;
}

@media screen and (max-width: 1380px) {
  .wrapper .images {
    border-radius: 26px;
    min-width: 580px;
    max-width: 580px;
    object-fit: cover;
  }
  .left_block {
    width: 40%;
  }
}
@media screen and (max-width: 1250px) {
  .wrapper .images {
    min-width: 440px;
    max-width: 440px;
  }
  .left_block {
    width: 60%;
  }
}
@media screen and (max-width: 1000px) {
  .main_wrapper .images {
    min-width: 385px;
    max-width: 385px;
    min-height: 720px;
    max-height: 720px;
  }
}
@media screen and (max-width: 900px) {
  .wrapper .images {
    min-width: 285px;
    max-width: 285px;
    min-height: 720px;
    max-height: 720px;
  }
  .content_block h5 {
    font-size: 40px;
  }
  .content_block p {
    font-size: 14px;
  }
  .check label {
    font-size: 14px;
  }
}
@media screen and (max-width: 768px) {
  .wrapper .images {
    display: none;
  }
  .left_block {
    width: 100%;
  }
}
