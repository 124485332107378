.wrapper {
  display: flex;
  flex-direction: column;
  gap: 54px;
}
.location {
  display: flex;
  flex-direction: column;
  gap: 14px;
}
.upload {
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.upload_item {
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.location div {
  display: flex;
  flex-direction: column;
  gap: 14px;
  max-width: 520px;
}
.rooms {
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.time {
  display: flex;
  flex-direction: column;
  gap: 14px;
  position: relative;
}
.date_picker {
  width: 215px;
  height: 52px;
  padding: 0 26px;
  gap: 10px;
  border-radius: 33px;
  border: none;
  background-color: #f5f6f6;
  font-size: 17px;
}
.time::before {
  content: url(../../../assets/icons/arrow_select.png);
  position: absolute;
  top: 55px;
  left: 180px;
  z-index: 2;
  cursor: pointer;
}
.description {
  display: flex;
  flex-direction: column;
  gap: 14px;
}
.cost {
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.cost input {
  max-width: 220px;
}
.comment {
  display: flex;
  flex-direction: column;
  gap: 14px;
}
