.content_wrapper {
  width: 75%;
  height: fit-content;
  background-color: #282828;
  border-radius: 26px;
}
.close_icon {
  position: absolute;
  top: 25px;
  right: 25px;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
}
.close_icon:hover {
  background-color: #e0e0e0;
}
.header {
  width: 100%;
  background-color: white;
  border-radius: 26px;
  padding: 40px;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
}
.header_img {
  width: 193px;
  height: 193px;
  margin-right: 30px;
}
.header h5 {
  font-size: 40px;
  margin-bottom: 20px;
}
.container_links {
  width: 100%;
  background-color: rgba(40, 40, 40, 1);
  border-radius: 26px;
  padding: 80px 40px 40px 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  z-index: 0;
  top: -40px;
  gap: 20px;
}

.header_container {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  max-width: 894px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 40px;
}
.container_buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}
.links_btn {
  font-size: 40px;
  margin-bottom: 20px;
}

@media screen and (max-width: 1100px) {
  .header_container {
    width: 78%;
  }
}
@media screen and (max-width: 768px) {
  .header_container {
    width: 84%;
  }
  .header {
    padding: 30px;
  }
  .container_links {
    padding: 60px 30px 30px 30px;
  }
  .header_img {
    width: 170px;
    height: 170px;
    margin-right: 25px;
  }
  .header h5 {
    font-size: 32px;
    margin-bottom: 15px;
  }
  .links_btn {
    font-size: 32px;
    margin-bottom: 18px;
  }
}

@media screen and (max-width: 550px) {
  .header {
    flex-direction: column;
    align-items: start;
    padding: 20px;
  }
  .container_links {
    padding: 50px 20px 20px 20px;
  }
  .header h5 {
    font-size: 28px;
  }
  .links_btn {
    font-size: 28px;
  }
  .header_img {
    width: 120px;
    height: 120px;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 430px) {
  .container_buttons {
    justify-content: space-between;
  }
  .social_btn {
    margin-top: 4px;
    padding: 2px 2px 2px 16px;
    font-size: 12px;
  }
  .social_btn::after {
    margin-top: 4px;
    width: 40px;
    height: 40px;
  }
}
