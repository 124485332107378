.recoll {
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;
}
@media screen and (max-width: 1000px) {
  .title {
    width: 100% !important;
  }
}

.recoll h3 {
  width: 54%;
}

#grey_card {
  padding: 30px;
  height: 520px; /* Set a fixed initial height */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: height 0.3s ease; /* Add smooth transition for height changes */
  overflow: hidden; /* Hide overflow content initially */
}

.review_content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden; /* Hide overflow content */
}

.review_text {
  display: -webkit-box;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;
  overflow: hidden;
  transition: all 0.3s ease;
}

.review_text.expanded {
  -webkit-line-clamp: unset;
}

.expand_button {
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  padding: 0;
  margin-top: 10px;
  align-self: flex-start;
}

.user_info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 20px;
}

.user_info img {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 50%;
}

.position_text {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  #grey_card {
    padding: 20px;
  }
  
  #grey_card div h5 {
    font-size: 18px;
  }
  
  #grey_card div p {
    font-size: 14px;
  }
  
  .user_info img {
    width: 50px;
    height: 50px;
  }
}

@media screen and (max-width: 500px) {
  .recoll h3 {
    width: 100%;
  }
}

#grey_card.expanded {
  height: auto; /* Allow height to adjust to content when expanded */
  max-height: 1000px; /* Set a max-height for smooth transition */
}
